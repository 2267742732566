export class Dropdown {
  constructor() {
    this.initRegularDropdown();
    this.initIndustryDropdown();
    this.setRegularDropdownFromLocalStorage();
    this.setMultiSelectDropdownFromLocalStorage();
  }

  private initIndustryDropdown(): void {
    const multiselectDropdowns = document.querySelectorAll('.dropdown.multiselect');
    const multiselectInput = document.querySelector('.multiselect-input') as HTMLInputElement;
    multiselectDropdowns.forEach((dropdown: HTMLElement) => {
      const dropdownItems = dropdown.querySelectorAll('.dropdown-item') as NodeListOf<HTMLElement>;
      const dropdownValue = dropdown.querySelector('.dropdown-value') as HTMLElement;
      const dropdownInputContainer = dropdown.querySelector('.dropdown-input-container') as HTMLElement;
      const dropdownPlaceholder = dropdown.querySelector('.dropdown-value-placeholder') as HTMLElement;
      const emptyDropdown = dropdown.querySelector('.multiselect-empty') as HTMLElement;
      const multiselectInput = dropdown.querySelector('.multiselect-input') as HTMLInputElement;

      if (dropdownItems.length !== 1) {
        emptyDropdown.classList.add('hidden');
      }

      dropdownInputContainer.addEventListener('click', (e) => {
        dropdown.classList.toggle('active');
        document.querySelectorAll('.dropdown:not(.multiselect)').forEach((el) => el.classList.remove('active'));

        e.stopPropagation();
      });

      dropdownItems.forEach((item: HTMLElement) => {
        this.handleDropdownItem(item, dropdown, dropdownValue, dropdownPlaceholder, emptyDropdown, multiselectInput);
      });

      document.addEventListener('click', (event) => {
        const isClickInsideElement = dropdown.contains(event.target as Node);

        if (!isClickInsideElement) {
          dropdown.classList.remove('active');
        }
      });
    });
  }

  private handleDropdownItem(
    item: HTMLElement,
    dropdown: HTMLElement,
    dropdownValue: HTMLElement,
    dropdownPlaceholder: HTMLElement,
    emptyDropdown: HTMLElement,
    multiselectInput: HTMLInputElement,
  ): void {
    item.addEventListener('click', (e) => {
      e.stopPropagation();
      const chips = document.createElement('div');
      chips.innerHTML = item.innerHTML;
      chips.dataset.dropdownValue = item.dataset.dropdownValue;
      chips.classList.add('multiselect-selected');
      dropdownValue.appendChild(chips);
      multiselectInput.value += `${item.dataset.dropdownValue} `;
      dropdownPlaceholder.classList.add('hidden');
      localStorage.setItem('multiselectInputValue', multiselectInput.value);

      this.handleChips(chips, dropdown, emptyDropdown, multiselectInput);

      item.remove();

      const currentItems = dropdown.querySelectorAll('.dropdown-item');
      if (currentItems.length === 0) {
        emptyDropdown.classList.remove('hidden');
      }

      dropdown.classList.remove('active');
    });
  }

  private handleChips(chips: HTMLElement, dropdown: HTMLElement, emptyDropdown: HTMLElement, multiselectInput: HTMLInputElement): void {
    const icon = chips.firstElementChild.querySelector(':scope > .multiselect-item-close');
    const dropdownList = dropdown.querySelector('.dropdown-list');
    const dropdownValue = dropdown.querySelector('.dropdown-value') as HTMLElement;
    const placeholder = dropdown.querySelector('.dropdown-value-placeholder') as HTMLElement;

    icon.addEventListener('click', (e) => {
      e.stopPropagation();
      const chips = icon.parentElement.parentElement;
      const chipsText = chips.innerHTML;
      const dropdownItem = document.createElement('div');

      dropdownItem.innerHTML = chipsText;
      dropdownItem.classList.add('dropdown-item');
      dropdownItem.dataset.dropdownValue = chips.dataset.dropdownValue;
      dropdownList.appendChild(dropdownItem);
      multiselectInput.value = multiselectInput.value.replace(`${chips.dataset.dropdownValue} `, '');
      chips.remove();
      localStorage.removeItem('multiselectInputValue');
      localStorage.setItem('multiselectInputValue', multiselectInput.value);

      this.handleDropdownItem(dropdownItem, dropdown, dropdownValue, placeholder, emptyDropdown, multiselectInput);

      const currentItems = dropdown.querySelectorAll('.dropdown-item');
      const selectedIndustries = dropdown.querySelectorAll('.multiselect-selected');
      if (currentItems.length === 1) {
        emptyDropdown.classList.add('hidden');
      }
      if (selectedIndustries.length === 0) {
        placeholder.classList.remove('hidden');
      }
    });
  }

  private initRegularDropdown() {
    const regularDropdowns = document.querySelectorAll('.dropdown:not(.multiselect)');
    regularDropdowns.forEach((dropdown) => {
      const input = dropdown.querySelector('input');
      const dropdownItems = dropdown.querySelectorAll('.dropdown-item');
      const dropdownValue = dropdown.querySelector('.dropdown-value');
      const dropdownInputContainer = dropdown.querySelector('.dropdown-input-container');

      dropdownInputContainer.addEventListener('click', (e) => {
        document.querySelectorAll('.dropdown.multiselect').forEach((el) => el.classList.remove('active'));

        dropdown.classList.toggle('active');
      });

      dropdownItems.forEach((item) => {
        item.addEventListener('click', (e) => {
          input.value = item.attributes['data-dropdown-value'].value;
          localStorage.setItem('dropdownInputValue', input.value);
          dropdownValue.innerHTML = item.innerHTML;
          dropdown.classList.remove('active');
        });
      });

      document.addEventListener('click', (event) => {
        const isClickInsideElement = dropdown.contains(event.target as Node);

        if (!isClickInsideElement) {
          dropdown.classList.remove('active');
        }
      });
    });
  }

  public setRegularDropdownFromLocalStorage(): void {
    const regularDropdowns = document.querySelectorAll('.dropdown:not(.multiselect)');
    regularDropdowns.forEach((dropdown) => {
      const input = dropdown.querySelector('input');
      const dropdownValue = dropdown.querySelector('.dropdown-value');
      const dropdownItems = dropdown.querySelectorAll('.dropdown-item');
      const storedValue = localStorage.getItem('dropdownInputValue');
      if (storedValue) {
        dropdownItems.forEach((item: HTMLElement) => {
          if (item.dataset.dropdownValue === storedValue) {
            input.value = item.dataset.dropdownValue;
            dropdownValue.innerHTML = item.innerHTML;
            dropdown.classList.remove('active');
          }
        });
      }
    });
  }

  public setMultiSelectDropdownFromLocalStorage(): void {
    const multiselectDropdowns = document.querySelectorAll('.dropdown.multiselect');

    multiselectDropdowns.forEach((dropdown: HTMLElement) => {
      const dropdownItems = dropdown.querySelectorAll('.dropdown-item') as NodeListOf<HTMLElement>;
      const dropdownValue = dropdown.querySelector('.dropdown-value') as HTMLElement;
      const dropdownPlaceholder = dropdown.querySelector('.dropdown-value-placeholder') as HTMLElement;
      const emptyDropdown = dropdown.querySelector('.multiselect-empty') as HTMLElement;
      const multiselectInput = dropdown.querySelector('.multiselect-input') as HTMLInputElement;

      const storedValue = localStorage.getItem('multiselectInputValue');

      if (storedValue) {
        const storedValues = storedValue.split(' ');

        storedValues.forEach((value) => {
          dropdownItems.forEach((item: HTMLElement) => {
            if (item.dataset.dropdownValue === value) {
              const chips = document.createElement('div');
              chips.innerHTML = item.innerHTML;
              chips.dataset.dropdownValue = item.dataset.dropdownValue;
              chips.classList.add('multiselect-selected');
              dropdownValue.appendChild(chips);
              if (!multiselectInput.value.includes(item.dataset.dropdownValue)) {
                multiselectInput.value += `${item.dataset.dropdownValue} `;
              }
              dropdownPlaceholder.classList.add('hidden');

              item.remove();

              this.handleChips(chips, dropdown, emptyDropdown, multiselectInput);

              const currentItems = dropdown.querySelectorAll('.dropdown-item');
              if (currentItems.length === 0) {
                emptyDropdown.classList.remove('hidden');
              }

              dropdown.classList.remove('active');
            }
          });
        });
      }
    });
  }
}
