import { initPhoneMask } from './book-demo/utils';
import { RequestFormValidation } from './book-demo/request-form-validation';

export const contactUsForm = () => {
  const formValidation = new RequestFormValidation();
  const form = document.querySelector('form.page-form') as HTMLFormElement;
  const submitButton = document.querySelector('form.page-form>[type=Submit]') as HTMLButtonElement;
  const phoneNumberSelector = '#id_phone_number';

  initPhoneMask(phoneNumberSelector);

  submitButton.setAttribute('disabled', 'true');
  formValidation.formChanged(form, submitButton, phoneNumberSelector);
};
